.container-projects {
  background-image: url(../assets/projects/pc.jpg);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100vw 100vh;
  padding-top: 80px;
}
.nav-link{
  cursor: pointer;
}
.card{
  min-width: 25%;
    display: block;
    margin:2.5%;
    border-radius: 10px;
}


.card img {
  width: 100%;
  object-fit: cover;
  object-position: top;
  height: 270px;
  transition: 8s all ease;
  border-radius: 10px 10px 0px 0px;

}
.card img:hover {
  object-position: bottom;
}
.btn-secondary {
  color: #fff;
  background-color: #0f52ba;
  border-color: #0f52ba;
  bottom: 3%;
  position: absolute;
  float: right;
  right: 0;
  border-radius: 6px;
  margin-right: 16px;
}
.btn-secondary:hover {
  color: #000;
  background-color: #ffdf00;
  border-color: #ffdf00;
}
.css-1dujl4k-TimelineMainWrapper{
  overflow-y:hidden!important
}

.load-more-btn{
  background: transparent;
  border: none;
  color: #0f52ba;
  font-weight: 600;
}