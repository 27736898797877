.container-skills {
  background-color: #ffffff;
  /* Negative margin not working (?) */
  transform: translateY(-90px);
  border-radius: 25px;
  margin: auto;
  max-width: 65vw;
}
.icons{
 margin:0 auto!important;
 padding: 5px;
}
h4{
  margin: 0 auto;
  margin-bottom:30px;
  margin-top:30px;
}

.row{
  padding:5px;
} 
