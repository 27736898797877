.container-experience {
  background-image: url(../assets/experience/back.jpg);
  background-attachment: fixed;
  background-position: bottom;
  background-repeat: no-repeat;
  padding-top: 80px;
  background-size: cover;
}
.timeline-card-content header{
  margin-left: 2%!important;
}

.position{
  color: rgb(15, 82, 186);
  font-size: 0.85rem;
  font-weight: 600;
  margin: 0px;
  text-align: left;
  width: 97%;
  margin-bottom: 7px;
}

h4{
  margin-top: 25px!important;
  margin-bottom: 15px!important;
}

@media (max-width: 768px) {
  .experience-image {
    display: none;
  }

  h4{
    margin-top: 0px!important;
    margin-bottom: 5px !important;
    font-size: 16px!important;
  }
  .dWNZQD{
    font-size: 12px!important;
    text-align: center!important;
  }
  .dWNZQD.active {
    font-size: 12px!important;
    text-align: center!important;
  }
}
