.icons {
  /* display: inline; */
  margin-right: 20px;
  width: 48px;
  height: auto;
}

/* Animated underline effect, credit to: https://codepen.io/parkinson/pen/xdPrJL */
 .animated-underline {
	display: inline-block;
	padding-bottom: 3px;
}
.animated-underline:after {
	content: '';
	display: block;
	margin: auto;
	height: 2px;
	animation: underline 2s infinite;
}
@keyframes underline {
  0% { width: 0%; background-color: #0f52ba;}
  100% {width: 100%; background-color: transparent;}
}