.content {
  display: inline-block;
  /* clamp function is not supported (?) */
  font-size: 19px;
  line-height: 5vh;
  width: 45vw;
  max-width: 600px;
  min-width: 300px;
}
.illustration {
  margin: 20px;
  /* height: auto; This will overwrite margin*/ 
  width: 100%;
  max-width: 800px;
}


